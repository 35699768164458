





















































import { Component, Vue } from 'vue-property-decorator';
import { dispatchUpdateAppByCode, dispatchreadAppByCode } from '@/store/devs/actions';

@Component
export default class ServiceProviderEdit extends Vue {
  public valid = true;
  public name: string = '';
  public application_code: string = '';
  public description: string = '';
  public is_active: boolean = false;
  public is_live: boolean = false;
  // public created_at: ;
  public AppCode:string = '';
  public appInfo = {};




public async mounted() {
    
    const id:string = this.$router.currentRoute.params.application_code;
    this.AppCode = id;

    const response = await dispatchreadAppByCode(this.$store, id);
    
    if (response) {
    
      this.name = response.name;
      this.application_code = response.application_code;
      this.description = response.description;
      this.is_active = response.is_active;
      this.is_live = response.is_live;
    //   this.created_at = response.created_at;
    //   this.providerFee = response.serviceproviderFee;
    //   this.hulupayFee = response.hulupayFee;
    }
  }




 

  public reset() {
      this.name = "";
      this.application_code = "";
      this.description = "";
      this.is_active = false;
      this.is_live = false;
    //   this.created_at = "";
     
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
   
        this.appInfo["AppCode"] = this.AppCode;
        this.appInfo["name"] = this.name;
        this.appInfo["application_code"] = this.application_code;
        this.appInfo["description"] = this.description;
        this.appInfo["is_active"] = this.is_active;
        this.appInfo["is_live"] = this.is_live;
        // this.provider["created_at"] = this.created_at;
        // this.provider["serviceproviderFee"] = this.providerFee;
        // this.provider["hulupayFee"] = this.hulupayFee;
                      

      await dispatchUpdateAppByCode(this.$store, this.appInfo);
      this.$router.push('/main/devs/apps/all');
    }
  }
}
